.EventTiles {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}
	
.EventTile {
	background-color: lightgrey;
	color: black;
	border: 1px solid grey;
	border-radius: 10px;
	
	margin: 10px 10px;
	width: 400px;
	height: 150px;
	overflow: hidden;
	
}

.EventTile:hover{
	margin: 9px 9px;
	border: 2px solid rgb(231, 231, 231);
	}
	
.EventTile > p{
	font-size: 1.3em;
}

.EventTile > img{
	width: 100%;
	height: 100%;
}

@media (max-width: 400px){
	.EventTile {
		width: 95%;
		display: flex;
		justify-content: center;
	}

	.EventTile > img{
		width: 80%;
		height: 80%;
		margin: auto;
	}

}