.Logo {
	font-size: 2.5em;
	margin: 0px;
	padding-left: 5%;
	cursor: pointer;
	text-decoration: none;
	color: inherit;
}

.Logo > h1 {
	margin: 0px;
}

.TopBar {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
   width: 100%; 
   background-color: #323232;
}

.TempLink {
	font-size: 1em;
	text-decoration: none;
	color:inherit;
	align-self: flex-end;
}