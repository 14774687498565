
/*-----EventPage------*/
.EventPage{
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Panel {
	background-color: rgba(255,255,255,0.15);
	padding: 20px;
	border-radius: 5px;

	display: flex;
	flex-direction: row;
	justify-content: space-around;

}


.EventPanel {
	flex-wrap: wrap;
	margin: 10px auto;
	width: 90%;
	max-width: 1280px;
}

.EventDetails {
	margin: 20px;
	min-width: 200px;
}



.VideoWrapper {
	width: 100%;
	min-width: 300px;
	max-width: 560px;
    position: relative;
	height: 315px;
}
.VideoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.MusicWrapper {
	height: 80px;
}

.Description {
	font-family: Helvetica, Arial, sans-serif;
	padding: 10px;
	max-width: 500px;
	
	text-align: left;
}


/*-----MoviePanel------*/
.MoviePanel {
	max-width: 700px;
	margin: 10px auto;

}

.MoviePanelTitle{
	font-size: 2em;
}


/*--------Mobile Specification----*/
@media (max-width:560px){
	.Panel {
		margin: 10px 5px;
	}
	.EventDetails {
		margin: 5px;
		max-width: 97%;
	}
	.EventPage{
		display: flex;
		flex-direction: column;
	}

	.VideoWrapper {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 25px;
		height: 0;
		overflow:hidden;
	}

	.VideoWrapper iframe{
		position: absolute;
		top:0;
		left:0;
		width: 100%;
		max-width:560px;
		height: 100%;
		max-height: 315px;
	}

}
