.App {
  font-family: 'Bebas Neue Regular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
	
	display: flex;
	flex-direction: column;
	
	position: absolute;
	width: 100%;
	height: 100%;
	
}


h2{
	font-size: 3.5em;
	margin: 0px;
}

h3{
	font-size: 1.3em;
	padding: 0px;
	margin: 3px;
}


p {
	font-family: Helvetica, Arial, sans-serif;
	margin: 10px;
}

