@font-face{
	font-family: 'Bebas Neue Regular';
	src: local('Bebas Neue Regular'), url(./assets/BebasNeueRegular.otf);
}


body {
   background-color: #151717;
	position: absolute;
	top: 0;
	left: 0;
	width:100%;
  margin: 0;

}
